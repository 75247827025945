import React, { useEffect } from 'react';
import { LogoImage, getRawDomainFromSearch, getRawDomain } from "./Theme";
import LoaderPR from "./LoaderPR";
import 'bootstrap/dist/css/bootstrap.min.css';
import './login.css';
import { serviceUrl } from "./SSOService";
import * as Utils from "./Utils";

function NoMatch() {    
    useEffect(() => {
        let domain = getRawDomainFromSearch();
        let rawDomain = getRawDomain();
        let returnUrl = `${serviceUrl}/checklogin`;
        if(domain && domain !== ""){
            returnUrl = `${returnUrl}?returnUrl=https://${domain}`;
        }else if(rawDomain && rawDomain !==""){
            returnUrl = `${returnUrl}?returnUrl=https://${rawDomain}`;
        }        
        Utils.redirectPage(returnUrl);
    })

    return (
        <form className="signin-form" name="loginForm">

            <LogoImage />
            <h2 className="page-header">Login</h2>
            <p>Redirecting to HealthNexus SSO</p>
            <LoaderPR showLoading={true} />

        </form>
    );

}

export default NoMatch;