export const serviceUrl = process.env.REACT_APP_SSO_URL;
export default class SSOService {
    async login(user, password, id, domain) {
        const body = { user, password, id: id, domain };
        return this.sendRequest(`${serviceUrl}/login`, body);
    }

    async checkSession(id, domain) {
        const body = { id: id, domain };
        return this.sendRequest(`${serviceUrl}/checksession`, body);
    }
    async forgotPassword(user) {
        const body = { user };
        return this.sendRequest(`${serviceUrl}/users/forgotpassword`, body);
    }

    async passwordRecovery(user, password, code) {
        const body = { user, password, code };
        return this.sendRequest(`${serviceUrl}/users/confirmforgotpassword`, body);
    }

    async setNewPassword(user, password, id, domain) {
        const body = { user, password, id, domain };
        return this.sendRequest(`${serviceUrl}/users/setnewpassword`, body);
    }

    async updatePassword(user, newPassword, password) {
        const body = { user, newPassword, password };
        return this.sendRequest(`${serviceUrl}/updatepassword`, body);
    }

    async sendRequest(url, body) {
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });

            if (response.status > 399) {
                return Promise.reject(await response.json());
            }
            return response.json();
        } catch (e) {
            return Promise.reject(e);
        }

    }
}

