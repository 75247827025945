import './passwordinput.css';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

import iconSet from "./icomoon/selection.json";
import IcomoonReact from "icomoon-react";

const PasswordInput = props => {
    const { onChange, name, label, validate, missmatch, autoComplete=false } = props;
    const [shouldHide, setHide] = useState(true);
    const [password, setPassword] = useState("");
    const [hasError, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const type = shouldHide ? "password" : "text";
    const iconType = shouldHide ?  "view-password" : "hide-password";
    const pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/;
    return (
        <Form.Group controlId={name} className="form-group">
            <Form.Label className="color1">{label}</Form.Label>
            <div className="input-password-container">
                <input
                    type={type}
                    value={password}
                    onChange={event => {
                        const value = event.target.value;
                        setPassword(value);
                        if (value.trim() === "") {
                            setError(true);
                            setErrorMessage("Password is Required");
                            onChange("");
                            return;
                        }
                        if (validate && !pattern.test(value)) {
                            setError(true);
                            setErrorMessage("Password must contain at least one lowercase letter, one uppercase letter, one number and one symbol. With a minimum length of 8 characters.");
                            onChange("");
                            return;
                        }
                        setError(false);
                        setPassword(value);
                        onChange(value);
                    }}
                    className="form-control"
                    name={name}
                    id={name}
                    autoComplete={autoComplete ? "current-password" : "new-password"} />
                <IcomoonReact data-testid="show-password" onClick={() => setHide(!shouldHide)}
                    className="icon-view-password"
                    iconSet={iconSet}
                    color="#444"
                    size={18}
                    icon={iconType} />

            </div>
            {(hasError || missmatch) && <p className="help-block has-error"  >{missmatch ? "Password Missmatch" : errorMessage}</p>}
        </Form.Group>
    );
};

export default PasswordInput;