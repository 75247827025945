import "bootstrap/dist/css/bootstrap.min.css";
import "./login.css";
import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { redirectPage } from "./Utils";
import { NotificationContext } from "./Notification";
import LoaderPR from "./LoaderPR";
import { LogoImage, getQueryParams } from "./Theme";
import PasswordInput from "./PasswordInput";
import SSOService from "./SSOService";

function PasswordRecoveryCode() {
  const codePattern = /\d{6}/;
  const { email } = useParams();
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const [missmatch, setMissmatch] = useState(false);
  const [error, setError] = useState(false);
  const [showLoading, setLoading] = useState(false);
  const { addToast } = useContext(NotificationContext);
  const service = new SSOService();
  const queryParams = getQueryParams();
  return (
    <form className="signin-form" name="loginForm">
      <LogoImage />
      <h2 className="page-header">Reset Password</h2>
      <p>
        Please check your email ({email}) look in both inbox and spam for your
        reset password request confirmation code (6 digit) and enter it here to
        confirm your identity.
      </p>

      <div className={error ? "has-error form-group" : "form-group"}>
        <label
          htmlFor="code"
          className={
            error ? "has-error control-label color1" : "control-label color1"
          }
        >
          Code
        </label>
        <input
          type="text"
          value={code}
          onChange={(event) => {
            const codeInput = event.target.value;
            if (!codePattern.test(codeInput)) {
              setError(true);
            } else {
              setError(false);
            }
            setCode(event.target.value);
          }}
          id="code"
          className={error ? "has-error form-control" : "form-control"}
          autoFocus=""
        />
      </div>
      <LoaderPR showLoading={showLoading} />

      <PasswordInput
        onChange={(newPassword) => setPassword(newPassword)}
        name="password"
        label="Please add your new password"
        validate={true}
        autoComplete={false}
      />
      <PasswordInput
        onChange={(retypeNewPassword) => {
          setMissmatch(retypeNewPassword.trim() !== password.trim());
          setRetypePassword(retypeNewPassword);
        }}
        name="retypepassword"
        label="Confirm new password"
        validate={false}
        missmatch={missmatch}
        autoComplete={false}
      />
      <div className="reset-password-buttons-container">
        <button
          disabled={showLoading}
          className="btn btn-lg btn-primary btn-block"
          type="submit"
          onClick={(event) => {
            event.preventDefault();
            let hasError =
              retypePassword.trim() !== password.trim() ||
              password.trim() === "" ||
              retypePassword.trim() === "";
            hasError = hasError || !codePattern.test(code) || missmatch;            
            if (hasError) {
              addToast("All fields are required", "error");
              return;
            }
            setLoading(true);
            service
              .passwordRecovery(email, password, code)
              .then(() => {
                redirectPage(`/login${queryParams}`);                
              })
              .catch((err) => {
                setLoading(false);
                addToast(err.error, "error");
              });
          }}
        >
          Reset
        </button>
        <button
          disabled={showLoading}
          className="btn btn-lg btn-primary btn-block"
          type="submit"
          onClick={(event) => {
            event.preventDefault();
            redirectPage(`/login${queryParams}`);
          }}
        >
          Cancel
        </button>
      </div>
    </form>
  );
}

export default PasswordRecoveryCode;
