import React, { useState, useEffect, createContext, useReducer, useContext } from 'react';
import "./notification.css";
const initialValue = {};
export const NotificationContext = createContext(initialValue);

export const NotificationState = ({ children }) => {
    const [notifications, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case "add":
                const message = action.notification;
                const existsMessage = Object.values(state).filter(notification => {
                    return notification.type === message.type && notification.message === message.message;
                }).length > 0;
                if (!existsMessage) {
                    state[action.notification.id] = action.notification;
                }
                return { ...state };
            case "remove":
                const newState = { ...state };
                delete newState[action.notification.id];
                return newState;
            default:
                return state;
        }
    }, initialValue);
    const addToast = (message, type, style = null) => {
        const crypto = window.crypto || window.msCrypto || {"getRandomValues":()=> "test"};
        const id = crypto.getRandomValues(new Uint32Array(1)).toString();
        const newNotification = { message, type, id, style };
        dispatch({ type: "add", notification: newNotification });
    };
    const removeToast = (notification) => {
        dispatch({ type: "remove", notification });
    };
    return (
        <NotificationContext.Provider value={{ notifications, addToast, removeToast }}>
            <div className="ui-notification-container">
                {Object.values(notifications).map(notification => (<NotificationMessage key={notification.id} notification={notification} />))}
            </div>
            {children}
        </NotificationContext.Provider>
    )
};
const NotificationMessage = ({ notification }) => {
    const { type, message, style } = notification;
    const [transition, setTransition] = useState("");
    const globalState = useContext(NotificationContext);
    const { removeToast } = globalState;
    const styleType = type;
    useEffect(() => {
        let showTimer;
        const timer = setTimeout(() => {
            showTimer = setTimeout(() => {
                clearTimeout(showTimer);
                removeToast(notification);
            }, 1000);

            if (transition !== "killed") {
                setTransition("killed");
            }
        }, 5000);
        return () => clearTimeout(timer);
    });

    return (
        <div style={style} className={`ui-notification ${styleType} ${transition}`}>
            <div className="message" >{message}</div>
        </div>
    );
};




