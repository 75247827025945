import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  LogoImage,
  setup,
  getDomainFromSearch,
  getRawDomainFromSearch,
  getPageToRedirect,
} from "./Theme";
import LoaderPR from "./LoaderPR";
import * as Utils from "./Utils";
import "bootstrap/dist/css/bootstrap.min.css";
import "./login.css";

function Setup() {
  const { id } = useParams();
  const domain = getDomainFromSearch();
  const rawDomain = getRawDomainFromSearch();
  let pageToRedirect = getPageToRedirect();
  if (pageToRedirect === null) {
    pageToRedirect = "login";
  }
  useEffect(() => {
    setup(id, domain, rawDomain);
    window.localStorage.removeItem("password");
    window.localStorage.removeItem("email");
    window.localStorage.removeItem("force");
    Utils.redirectPage(`/${pageToRedirect}?domain=${rawDomain}`);
  }, []);
  return (
    <form className="signin-form" name="loginForm">
      <LogoImage />
      <h2 className="page-header">Login</h2>
      <p>Waiting...</p>
      <LoaderPR showLoading={true} />
    </form>
  );
}

export default Setup;
