import React from 'react';
import "./loader.css";
const LoaderPR = ({ showLoading }) => {
    return (showLoading && <div className="loader">
        <div className="spinerAnimation">
            <div>
                <div className="row">
                    <div className="cell a bg-primary"></div>
                    <div className="cell b bg-primary"></div>
                    <div className="cell c bg-primary"></div>
                </div>
                <div className="row">
                    <div className="cell b bg-primary"></div>
                    <div className="cell c bg-primary"></div>
                    <div className="cell d bg-primary"></div>
                </div>
                <div className="row">
                    <div className="cell c bg-primary"></div>
                    <div className="cell d bg-primary"></div>
                    <div className="cell e bg-primary"></div>
                </div>
            </div>
        </div>
    </div>);
}

export default LoaderPR;