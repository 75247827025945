import "bootstrap/dist/css/bootstrap.min.css";
import "./login.css";
import React, { useState, useContext } from "react";
import { NotificationContext } from "./Notification";
import * as Utils from "./Utils";
import LoaderPR from "./LoaderPR";
import {
  LogoImage,
  getQueryParams,
  getRawDomainFromSearch,
  getPSI,
} from "./Theme";
import PasswordInput from "./PasswordInput";
import EmailInput from "./EmailInput";
import SSOService from "./SSOService";

function UpdatePassword() {
  const storageEmail = window.localStorage.getItem("email");
  const storePassword = window.localStorage.getItem("password");
  const domain = getRawDomainFromSearch();
  const id = getPSI();
  const forceUpdateState = useState(
    window.localStorage.getItem("force") === "yes"
  );
  const forceUpdatePassword = forceUpdateState[0];
  const [email, setEmail] = useState(!forceUpdatePassword ? "" : storageEmail);
  const [oldPassword, setOldPassword] = useState(
    !forceUpdatePassword ? "" : storePassword
  );
  const pageTitle = !forceUpdatePassword ? "Update Password" : "Change Password";
  const [password, setPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");

  const [missmatch, setMissmatch] = useState(false);
  const [showLoading, setLoading] = useState(false);

  const { addToast } = useContext(NotificationContext);
  const service = new SSOService();
  const queryParams = getQueryParams();
  const clean = () => {
    window.localStorage.removeItem("password");
    window.localStorage.removeItem("email");
    window.localStorage.removeItem("force");
  };

  return (
    <form className="signin-form" name="loginForm">
      <LogoImage />
      <h2 className="page-header">{pageTitle}</h2>
      {forceUpdatePassword &&  <div className="resetpassword">
        <div>
          Your password must be reset every 180 days
        </div>        
      </div>}
      
      {!forceUpdatePassword && <EmailInput name="email" onChange={setEmail} />}

      {!forceUpdatePassword && (
        <PasswordInput
          onChange={(oldPassword) => setOldPassword(oldPassword)}
          name="oldpassword"
          label="Please add your old password"
          validate={false}
          autoComplete={false}
        />
      )}

      <LoaderPR showLoading={showLoading} />

      <PasswordInput
        onChange={(newPassword) => setPassword(newPassword)}
        name="password1"
        label="Please add your new password"
        validate={true}
        autoComplete={false}
      />
      <PasswordInput
        onChange={(retypeNewPassword) => {
          setMissmatch(retypeNewPassword.trim() !== password.trim());
          setRetypePassword(retypeNewPassword);
        }}
        name="retypepassword1"
        label="Confirm new password"
        validate={false}
        missmatch={missmatch}
        autoComplete={false}
      />
      <div className="reset-password-buttons-container">
        <button
          disabled={showLoading}
          className="btn btn-lg btn-primary btn-block"
          type="submit"
          onClick={(event) => {
            event.preventDefault();
            let hasError =
              retypePassword.trim() !== password.trim() ||
              password.trim() === "" ||
              retypePassword.trim() === "";
            hasError = hasError || missmatch;
            hasError = hasError || oldPassword.trim() === "";
            hasError = hasError || email.trim() === "";
            if (hasError) {
              addToast("All fields are required", "error");
              return;
            }
            if (oldPassword === password) {
              addToast("Password Reuse Is Forbidden ", "error");
              return;
            }
            setLoading(true);
            service
              .updatePassword(email, password, oldPassword)
              .then(() => {
                addToast("Password Updated Successfully", "info");
                clean();
                if (id && domain) {
                  return service.login(email, password, id, domain);
                }
                Utils.redirectPage(`/login${queryParams}`, 5000);
              })
              .then((data) => {
                Utils.redirectPage(data.url);
              })
              .catch((err) => {
                if (err.changePassword === true) {
                  addToast(
                    "Admin Force To Reset Password. Please Review Your Email We Sent You A Recovery Code",
                    "error",
                    { height: "70px" }
                  );
                  Utils.redirectPage(
                    `/passwordrecovery/${email}${queryParams}`,
                    5000
                  );
                  clean();
                  return;
                }
                addToast(err.error, "error");
                setLoading(false);
              });
          }}
        >
          Update
        </button>
        <button
          disabled={showLoading}
          className="btn btn-lg btn-primary btn-block"
          type="submit"
          onClick={(event) => {
            event.preventDefault();
            clean();
            Utils.redirectPage(`/login${queryParams}`);
          }}
        >
          Cancel
        </button>
      </div>
    </form>
  );
}

export default UpdatePassword;
