export function redirectPage(pageToRedirect, timeout) {
  if (!timeout) {
    window.location.href = pageToRedirect;
    return;
  }
  const timer = setTimeout(() => {
    if (pageToRedirect !== "") {
      window.location.href = pageToRedirect;
      clearTimeout(timer)
    }
  }, timeout);  
}
