import "bootstrap/dist/css/bootstrap.min.css";
import "./login.css";

import React, { useState, useEffect } from "react";
import SSOService, { serviceUrl } from "./SSOService";
import { redirectPage } from "./Utils";

export const LogoImage = () => {
  const domain = getDomainFromSearch();
  const reseller = getReseller(domain) || domain;
  const defaultLogo = `${process.env.PUBLIC_URL}/logo.png`;
  const [logoSrc, setLogoSrc] = useState(
    domain
      ? `${process.env.PUBLIC_URL}/resellers/${reseller}/img/logo.png`
      : defaultLogo
  );
  return (
    <img
      className="login-logo"
      src={logoSrc}
      onError={() => {
        setLogoSrc(defaultLogo);
      }}
      alt="ssologo"
    />
  );
};

function getReseller(domain) {
  const regexDomain =
    /^(provider-groups-|concept-groups-)?(\w+)(\.healthnexus\.io|-portal\.purplelab\.com|\.agilereportretriever\.com)$/;
  const resellerDomain = regexDomain.exec(domain);
  if (resellerDomain == null || resellerDomain.length < 3 || resellerDomain[2] == null) {
    return "";
  }
  return resellerDomain[2];
}

export function getQueryParams() {
  const domainSearch = getRawDomainFromSearch();
  if (domainSearch && domainSearch !== "") {
    return `?domain=${domainSearch}`;
  }
  const domain = getRawDomain();
  if (domain && domain !== "") {
    return `?domain=${domain}`;
  }
  return "";
}
export function setup(id, domain, rawDomain) {
  window.localStorage.setItem("PSI", id);
  window.localStorage.setItem("PDOMAIN", domain);
  window.localStorage.setItem("RAWPDOMAIN", rawDomain);
}

export function handleSessionValidation(addToast) {
  const id = getPSI();
  const domain = getRawDomain();
  const rawDomain = getRawDomainFromSearch();
  const service = new SSOService();
  const isError = !id || !domain;
  const errorMessage = "Session timed out. Please reenter credentials";
  return new Promise((resolve, reject) => {
    if (rawDomain && rawDomain !== "" && isError) {
      addToast(errorMessage, "error", { height: "60px" });
      redirectPage(
        `${serviceUrl}/checklogin?returnUrl=${getReturnUrl(rawDomain)}`
      );
      reject(errorMessage);
      return;
    } else if (isError) {
      addToast(errorMessage, "error", { height: "60px" });
      redirectPage(`${serviceUrl}/checklogin`);
      reject(errorMessage);
      return;
    }
    return service
      .checkSession(id, domain)
      .then(() => {
        resolve();
      })
      .catch((err) => {
        addToast(errorMessage, "error", { height: "60px" });
        if (err.refreshSession === true) {
          redirectPage(`${serviceUrl}/checklogin?returnUrl=${err.returnUrl}`);
          reject(errorMessage);
          return;
        }
        window.location.href = redirectPage(`${serviceUrl}/checklogin`);
        reject(errorMessage);
      });
  });
}

function getReturnUrl(domain) {
  if (domain === "localhost") {
    return "http://localhost:8080";
  }
  return `https://${domain}`;
}

export function getPSI() {
  return window.localStorage.getItem("PSI");
}
export function getDomain() {
  return window.localStorage.getItem("PDOMAIN");
}

export function getRawDomain() {
  return window.localStorage.getItem("RAWPDOMAIN");
}

export function getDomainFromSearch() {
  const search = window.location.search;

  const domain = new URLSearchParams(search).get("domain");
  if (domain && domain.split("dev-").length > 1) {
    return domain.split("dev-")[1];
  }
  if (domain && domain.split("dev.").length > 1) {
    return domain.split("dev.")[1];
  }
  if (domain && domain.split("dev").length > 1) {
    return domain.split("dev")[1];
  }
  return domain || "";
}
export function getRawDomainFromSearch() {
  const search = window.location.search;
  const domain = new URLSearchParams(search).get("domain");
  return domain || "";
}

export function getPageToRedirect() {
  const search = window.location.search;
  const page = new URLSearchParams(search).get("pageToRedirect");
  return page || null;
}

const Theme = ({ children }) => {
  useEffect(() => {
    if (window.location.hash && window.location.hash.length > 0) {
      window.history.replaceState(null, null, " ");
    }
  });
  return <div className="App">{children}</div>;
};

export default Theme;
