import React, { useState } from 'react';

const EmailInput = ({ onChange, name }) => {
    const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    const [emailvalue, setEmail] = useState("");
    const [hasError, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    return (
        <div className="form-group">
            <label htmlFor={name} className="control-label color1">Username (Email)</label>
            <input type="text" value={emailvalue} onChange={event => {
                const value = event.target.value;
                if (value.trim() === "" || !emailRegex.test(value)) {
                    setError(true);
                    setErrorMessage("Email is Required.");
                    setEmail(value);
                    onChange("");
                    return;
                }
                setError(false);
                setEmail(value);
                onChange(value);

            }} id={name} className={hasError ? "has-error form-control" : "form-control"} autoFocus="" />
            {hasError && <p className="help-block has-error"  >{errorMessage}</p>}
        </div>
    );
}

export default EmailInput;