import 'bootstrap/dist/css/bootstrap.min.css';
import './login.css';
import React, { useState, useContext, useEffect } from 'react';
import { decode } from 'js-base64';
import {
    useParams
} from "react-router-dom";
import { NotificationContext } from "./Notification";
import LoaderPR from "./LoaderPR";
import { redirectPage } from "./Utils";
import { LogoImage, getDomain, getPSI, handleSessionValidation } from "./Theme";
import PasswordInput from "./PasswordInput";
import SSOService, { serviceUrl } from "./SSOService";


function SetNewPassword() {
    const domain = getDomain();
    const { email } = useParams();
    const id = getPSI();
    const [password, setPassword] = useState("");
    const [showLoading, setLoading] = useState(false);
    const { addToast } = useContext(NotificationContext);    
    const emailDecoded = decode(email);
    const service = new SSOService();
    useEffect(() => {
        setLoading(true);
        handleSessionValidation(addToast).then(() => {
            setLoading(false);
        })
    }, []);
    return (

        <form className="signin-form" name="loginForm" autoComplete="on">

            <LogoImage />
            <h2 className="page-header">Set New Password</h2>

            <PasswordInput onChange={passwordInput => {
                setPassword(passwordInput);
            }}
                name="password"
                label="New Password"
                validate={true}
                missmatch={false}
                autoComplete={true}
            />
            <LoaderPR showLoading={showLoading} />
            <div className="reset-password-buttons-container">
                <button className="btn btn-lg btn-primary btn-block" type="submit" onClick={(event) => {
                    event.preventDefault();
                    if (password.trim() === "") {
                        addToast("New Password is required", 'error');
                        return;
                    }
                    setLoading(true);
                    handleSessionValidation(addToast).then(() => {
                        service.setNewPassword(emailDecoded, password, id, domain).then(data => {                            
                            redirectPage(data.url);
                        }).catch(err => {
                            if (err.refreshSession === true) {
                                addToast('Your session expired.', 'error');
                                redirectPage(`${serviceUrl}/checklogin?returnUrl=${err.returnUrl}`);
                                return;
                            }
                            setLoading(false);
                            addToast(err.error, 'error');
                        });
                    });
                }} >Set New Password</button>
            </div>

        </form>

    );
}

export default SetNewPassword;
