import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Theme from "./Theme";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import PasswordRecoveryCode from "./PasswordRecoveryCode";
import SetNewPassword from "./SetNewPassword";
import UpdatePassword from "./UpdatePassword";
import NoMatch from "./NoMatch";
import Setup from "./Setup";
import { NotificationState } from "./Notification";

function App() {
  return (
    <NotificationState>
      <Theme>
        <Router>
          <Routes>
            <Route exact path="/forgot" element={<ForgotPassword />} />

            <Route
              exact
              path="/passwordrecovery/:email"
              element={<PasswordRecoveryCode />}
            />

            <Route
              exact
              path="/setnewpassword/:email"
              element={<SetNewPassword />}
            />

            <Route exact path="/updatepassword" element={<UpdatePassword />} />

            <Route exact path="/login" element={<Login />} />

            <Route exact path="/setup/:id" element={<Setup />} />

            <Route path="*" element={<NoMatch />} />
          </Routes>
        </Router>
      </Theme>
    </NotificationState>
  );
}

export default App;
